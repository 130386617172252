<template>
  <div class="primary-res">
    <template v-if="loading">
      <template v-if="switchShow == 1">
        <media ref="mpthree" :res="res" @list="getList" @down="allDown"></media>
      </template>
      <template v-else-if="switchShow == 2">
        <pdf ref="child" :res="res" @list="getList" @down="allDown" />
      </template>
      <template v-else>
        <div class="primary-resInfo">
          <p style="white-space: pre-wrap; text-align: center">
            {{ res.bookName }}
          </p>
          <p>{{ res.resName }}</p>
          <p class="mxy-noDown">
            <em v-if="res.bookSlogan">{{ res.bookSlogan }}</em>
            <em v-else>资源制作、上传中，敬请期待！</em>
          </p>
        </div>
      </template>
    </template>
    <template v-else>
      <template v-if="loadingExce">
        <div class="primary-nothing">
          <img class="cover" src="../assets/images/nothing.png" alt="" />
          <p class="title">您查找的内容不存在</p>
          <router-link
            :to="{
              name: 'propose',
              query: { bid: bid, fid: fid, rid: rid, source: source },
            }"
            class="btn"
            ><img class="icon" src="../assets/images/ope.png" alt="" />我要反馈
          </router-link>
        </div>
      </template>
      <template v-else>
        <div class="primary-folder-no">
          <van-loading>{{ loadingText }}</van-loading>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import qs from "qs";
import { Dialog } from "vant";
import pdf from "@/components/viewpdf.vue";
import media from "@/components/media";

export default {
  name: "resource",
  components: { pdf, media },
  data() {
    return {
      loading: false,
      loadingText: "解析中...",
      loadingExce: false,
      switchShow: 0, // 1音频  2PDF  0无资源
      bid: this.$route.query.bid == undefined ? "" : this.$route.query.bid,
      fid: this.$route.query.fid == undefined ? "" : this.$route.query.fid,
      rid: this.$route.query.rid == undefined ? "" : this.$route.query.rid,
      read: this.$route.query.read == undefined ? "" : this.$route.query.read, //是否有跟读
      source: 3,
      res: "",
      folder: "",
      book: "",
    };
  },
  watch: {},
  mounted() {
    //this.bid = '450976065297321984';
    //this.rid = '450976526943391744';
    //this.fid = '450976274731503616';
    //判断参数是否有值
    if (
      this.bid != undefined &&
      this.bid != "" &&
      this.fid != undefined &&
      this.fid != "" &&
      this.rid != undefined &&
      this.rid != ""
    ) {
      //2024.01.23 俩资源印同一个ID问题处理跳转一个临时目录页面 用户选择具体资源
      if (this.rid == "642473711290552320") {
        window.location.href =
          "https://res.quyixian.com/folder?fid=642473711290552320&bid=642473707146579968";
      }

      this.getRes();
      this.getBook();
    } else {
      this.loading = false;
      this.loadingText = "解析URL出错啦...";
      this.loadingExce = true;
    }
  },
  methods: {
    getRes() {
      this.loading = false;
      let params = {
        bid: this.bid,
        fid: this.fid,
        rid: this.rid,
      };
      this.$api.mary.GetResInfo(params).then((res) => {
        let { tag, message, data } = res;
        if (tag == 1) {
          this.loading = true;
          this.res = data;
          //判断是否有资源以及资源类型
          let type = this.res.fileExtendName;
          if (type == ".mp3" || type == ".wav" || type == ".MP3") {
            this.switchShow = 1;
          } else if (type == ".pdf" || type == ".PDF") {
            this.switchShow = 2;
          }
          document.title = this.res.resName + "-" + this.res.bookName;
        } else {
          this.loadingExce = true;
          this.loading = false;
          this.loadingText = "资源走丢了...";
        }
        //console.log(res);
      });
    },
    //获取图书
    getBook() {
      this.loading = false;
      let params = {
        bid: this.bid,
      };
      this.$api.mary.GetBookInfo(params).then((res) => {
        let { tag, message, data } = res;
        if (tag == 1) {
          this.book = data;
          this.loading = true;
        } else {
          this.loadingExce = true;
          this.loading = false;
          this.loadingText = "图书走丢了...";
        }
        //console.log(res);
      });
    },
    //下载资源记录
    allDown() {
      let params = {
        bid: this.bid,
        fid: this.fid,
        rid: this.rid,
      };
      this.$api.mary.DownResource(params).then((res) => {
        let { tag, message } = res;
        if (tag == 1) {
        } else {
          Dialog.alert({
            message: message,
          });
        }
      });
    },

    //组件获取数据列表
    async getList(v) {
      const folders = sessionStorage.getItem(this.bid);
      if (folders == null) {
        sessionStorage.clear();

        let params = {
          bid: this.bid,
          fid:
            this.fid == "642473711290552320" ? "642473707150774272" : this.fid, //2024.1.23印俩资源印同一码特殊处理目录ID查询,
        };
        let info = await this.$api.mary.GetFolderInfo(params);
        sessionStorage.setItem(info.data.bid, qs.stringify(info));
        this.folder = info;
      } else {
        this.folder = qs.parse(folders);
      }
      //子组件获取数据z
      //PDF组件
      if (v == "pdf") {
        this.$refs.child.getSess();
      }
      //MP3组件
      if (v == "media") {
        this.$refs.mpthree.getSess();
      }
      //
      // if(this.folder.tag == 1){
      //   this.folder = this.folder.data;
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.book {
  &-title {
    color: red;
  }
}

.mxy-noDown {
  flex: 1;
  margin-top: 3rem;
}
</style>
